import React from 'react'
import {Button,Box,TextField,Stack,InputAdornment} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
const Forgotpassword=()=>{
 return(
    <>
    <div className='login-container'>
        <div className='container'>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Box component="form"
                                sx={{
                                   
                                }}
                                noValidate
                                autoComplete="off"
                                >
                            <TextField id="email" label="Username/Email" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                            InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AccountCircle />
                                          
                                            </InputAdornment>
                                        ),
                                     }}
                              />

                            
                            
                            <Stack direction='column' spacing={2} sx={{display:'flex',justifyContent:'center'}}><Button variant='contained' color='success' >Send</Button></Stack>
                            <Stack direction='row' spacing={2} sx={{display:'flex',justifyContent:'space-between'}}>
                            <Link to="/register">I dont have an account </Link>
                            <Link to="/login">Login</Link></Stack>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
 )
}
export default Forgotpassword;
