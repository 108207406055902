import React, { useState } from 'react'
import {Button,Box,TextField,Stack,Checkbox,FormControlLabel,InputAdornment} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle';

import KeyIcon from '@mui/icons-material/Key';
import {Link} from 'react-router-dom'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const Login=()=>{
   const [showPassword,setShowPassword]=useState(false);
   const handlePasswordVisibility=()=>setShowPassword((show)=> !show);

    return(
        <>
        <div className='login-container'>
            <div className='container'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Box component="form"
                                    sx={{
                                       
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                        <Stack direction={'row'} sx={{display:'flex',justifyContent:'center'}}>
                                        <AccountCircle sx={{fontSize:80}} />
                                        </Stack>
                                <TextField id="email" label="Username/Email" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                                InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                <AccountCircle />
                                              
                                                </InputAdornment>
                                            ),
                                         }}
                                  />
                                
                                
                                <TextField id="email" label="Username/Email" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                                type={showPassword?'text':'password'}
                                InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                <KeyIcon />
                                              
                                                </InputAdornment>
                                            ),
                                            endAdornment:(
                                                <InputAdornment position='start' onClick={handlePasswordVisibility} sx={{cursor:'pointer'}} >
                                                   {showPassword?<VisibilityIcon />:<VisibilityOffIcon />} 
                                                </InputAdornment>
                                            ),
                                         }}
                                  />
                                  
                                <FormControlLabel control={<Checkbox /> } label="Remember me" />
                                <Stack direction='column' spacing={2} sx={{display:'flex',justifyContent:'center'}}><Button variant='contained' color='success' >Login</Button></Stack>
                                <Stack direction='row' spacing={2} sx={{display:'flex',justifyContent:'space-between'}}>
                                <Link to={'/register'}>I dont have an account </Link>
                                <Link to={"/forgot-password"} >Forgot Password</Link>
                                </Stack>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Login