
import './mystyle.css';
import Login from './components/Login.js';
import Register from './components/Register';
import Forgotpassword from './components/Forgotpassword';
import {Routes,Route} from 'react-router-dom';



function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Login />}></Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='/register' element={<Register />}></Route>
      <Route path='/forgot-password' element={<Forgotpassword />}></Route>
    </Routes>
    
    </>
  );
}

export default App;
