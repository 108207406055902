import React, { useState } from 'react'
import {Button,Box,TextField,Stack,InputAdornment} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
const Register=()=>{
    const [showPassword,setShowPassword]=useState(false);
    const [showCPassword,setCShowCPassword]=useState(false);
    const handlePasswordType=()=>{
        alert('pass');
    }

    const handelPasswordVisibility=()=>setShowPassword((show)=>!show);
    const handelCPasswordVisibility=()=>setCShowCPassword((show)=>!show);
 return(
    <>
    <div className='login-container'>
        <div className='container'>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Box component="form"
                                sx={{
                                   
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                    <Stack direction='row' spacing={2} sx={{display:'flex',justifyContent:'center'}}>

                                    <AccountCircle sx={{ fontSize: 80 }}/>
                                    </Stack>

                            <TextField id="f_name" label="First Name" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                            InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AccountCircle />
                                          
                                            </InputAdornment>
                                        ),
                                     }}
                              />
                            
                            <TextField id="l_name" label="Last Name" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                            InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AccountCircle />
                                          
                                            </InputAdornment>
                                        ),
                                     }}
                              />
                           
                            <TextField id="email" label="Username/Email" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                            InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <AccountCircle />
                                          
                                            </InputAdornment>
                                        ),
                                     }}
                              />
                            
                            <TextField id="password" label="Password" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                            type={showPassword?'text':'password'}
                            InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <LockIcon onClick={handlePasswordType} />
                                          
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="start" sx={{cursor:'pointer'}} onClick={handelPasswordVisibility}>
                                              {showPassword?<VisibilityIcon />:<VisibilityOffIcon />}
                                            </InputAdornment>
                                        ),
                                     }}
                              />
                            
                            <TextField id="c_password" label="Confirm Password" variant="outlined" className='col-12' sx={{marginBottom:1}} 
                            type={showCPassword?'text':'password'}
                            InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                             <LockIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="start"  sx={{cursor:'pointer'}}>
                                             {showCPassword?<VisibilityIcon onClick={handelCPasswordVisibility}  />:<VisibilityOffIcon onClick={handelCPasswordVisibility} />}
                                            </InputAdornment>
                                        ),
                                     }}
                              />

                        
                            <Stack direction='column' spacing={2} sx={{display:'flex',justifyContent:'center'}}><Button variant='contained' color='success' >Register</Button></Stack>
                            <Stack direction='row' spacing={2} sx={{display:'flex',justifyContent:'space-between'}}>
                  
                            <Link to="/login">I already have an account</Link></Stack>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
 )
}
export default Register;
